const traditionRoute = [
    {
        path: "/tradition",
        name: "TraditionCalendar",
        component: () =>
            import(
                /* webpackChunkName: "traditionCalendar" */ "./TraditionCalendar.vue"
            ),
    },
];

export default traditionRoute;
