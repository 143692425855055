<template>
    <div class="top-banner">
      <div class="clip">
        <div
          class="bg bg-bg-chrome act"
          v-lazy="'images/bg/bg-index.jpg'"
        ></div>
      </div>
      <div class="container box">
        <div class="row" style="padding-top: 30px; padding-bottom: 0">
          <div class="col-lg-12">
            <!-- Embedded Power BI Dashboard -->
            <iframe
              :src="powerBIDashboardUrl"
              title="dashboard community"
              width="100%"
              height="800"
              frameborder="0"
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CommunityDashboardPage",
    data() {
      return {
        powerBIDashboardUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiMTQxOTkxOTQtZTkyYy00NGY0LWEwZjktMjgxNTg3ZTA1ZmVmIiwidCI6IjczM2UyY2UwLWNlMjgtNGRmYS04YWY2LWFkNTdiMzcwOTBjZSIsImMiOjEwfQ%3D%3D",
      };
    },
  };
  </script>

<style scoped>

iframe {
  width: 100%;
  height: 800px;
  border: none;
}

.top-banner {
  position: relative;
  background-color: #f9f9f9;
  padding: 20px 0;
}

.tab_active {
    background-color: #e9eef4 !important;
    color: rgb(32, 106, 176) !important;
    padding: 13px !important;
    font-size: 20px !important;
    border-top: 2px solid !important;
    border-left: 2px solid !important;
    border-radius: 5px 0 0 0 !important;
}

.tap_notactive {
    background-color: #206ab0;
    color: #fff;
    padding: 15px;
    padding-bottom: 13px;
    font-size: 20px;
    border-radius: 0 5px 0 0;
}
.line_blue {
    margin-top: 13px !important;
    margin-bottom: 20px !important;
    border: 0 !important;
    border-top: 2px solid #206ab0 !important;
}
h3 {
    font-size: 30px;
    line-height: 2.363rem;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    text-align: center;
    color: rgb(47, 91, 210);
    text-shadow: 1px 1px 3px #000000;
    padding-bottom: 20px;
}

.center {
    text-align: center;
}

.sidebar-block {
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
}

.bg_block {
    background-color: #000000;
}

.padd-90-90 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.col-lg-12 {
    padding-right: 25px;
    padding-left: 45px;
}

.popular-img h4 {
    font-weight: 500;
    letter-spacing: 0px;
    padding-bottom: 10px;
    text-shadow: 1px 1px 1px black;
}

.popular-img .vertical-bottom {
    padding: 0px 0px 6px 6px;
    /* text-align:center; */
    backdrop-filter: blur(1px);
}

.c-button.small {
    font-size: 12px;
    padding: 8px 14px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.c-button.small span {
    font-weight: 500;
}

.imageSizss {
    width: 100%;
    height: 250px;
}

.img-Commu {
    float: left;
}

.borderCommunity {
    border: 10px solid red;
    padding: 5px;
    width: 100%;
    height: 250px;
    background-color: black;
}

.ppcont {
    width: 1000px;
    height: 600px;
}

#text-spac-page-covid {
    letter-spacing: 0;
}

.imgActivity {
    width: 150px;
    height: 100px;
    text-align: center;
}

.pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 15px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -16px 0 0 -13px;
    animation: pulsate 2.5s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    background: rgba(94, 190, 255, 0.5);
    box-shadow: 0 0 1px 2px #2d99d3;
    animation-delay: 1.1s;
}

.simple-tab .tab-info {
    border: 1px solid #e9eef4;
    margin-bottom: -30px;
    padding: 14px 14px;
}

.padd-90 {
    padding-bottom: 0px;
}

.grid-content.block-content .hotel-item.style-10 {
    padding-bottom: 0px;
}

.grid-content {
    margin-bottom: 0px;
}

.grid-content .hotel-item {
    margin-bottom: 0px;
}

.detail-wrapper {
    padding: 0px;
    margin-top: -30px;
}

.right-sidebar {
    object-fit: cover;
}

/*-----------scrollll----------------- */
/*
 *  STYLE 1
 */

.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff00;
}

.scrollbar::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff00;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgb(202, 202, 202);
}

.title_page {
    float: left;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(255, 255, 255, 0.696);
    border-radius: 25px;
}

.scrollbar {
    height: 70vh;
    overflow-y: scroll;
    /* padding: 40px 40px 40px 40px; */
    /* box-shadow: inset 1px 1px 12px #dddddd; */
}

.hotel-item {
    position: relative;
    width: 100%;

    display: inline-block;
    box-shadow: 0px 0px 0px white;
}

.input-style-1.b-50 input {
    font-size: 14px;
    height: 42px;
    padding: 0px 12px 0px 21px;
    color: #000000;
}

@media screen and (max-width: 600px) {
    .col-md-8 {
        padding-bottom: 40px;
    }

    .col-lg-12 {
        padding-right: 15px;
        padding-left: 15px;
    }
}
</style>
