const privacyRoute = [
    {
        path: "/PrivacyIndex",
        name: "PrivacyIndex",
        // route level code-splitting
        // this generates a separate chunk (privacyIndex.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "privacyIndex" */ "./PrivacyIndex.vue"),
    },
];

export default privacyRoute;
