import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

// export default {
//     install: (app, pluginConfig) => {
//         app.config.globalProperties.$CookieConsent = CookieConsent;
//         app.config.globalProperties.$CookieConsent.run(pluginConfig);
//     }
// }

CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "cloud",
            position: "bottom center",
            equalWeightButtons: true,
            flipButtons: false,
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false,
        },
    },
    categories: {
        necessary: {
            readOnly: true,
        },
        analytics: {},
    },
    language: {
        default: "en",
        translations: {
            en: {
                consentModal: {
                    title: "🍪 เว็บไซต์นี้ใช้ Cookie",
                    description:
                        "เว็บไซต์นี้ใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ ท่านสามารถตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิกการตั้งค่าคุกกี้",
                    acceptAllBtn: "ยอมรับ",
                    acceptNecessaryBtn: "ไม่ยอมรับ",
                    showPreferencesBtn: "ตั้งค่าคุกกี้",
                    footer: '<a href="PrivacyIndex">Privacy Policy</a>\n',
                },
                preferencesModal: {
                    title: "การตั้งค่าความเป็นส่วนตัว",
                    acceptAllBtn: "ยอมรับทั้งหมด",
                    acceptNecessaryBtn: "ไม่ยอมรับทั้งหมด",
                    savePreferencesBtn: "บันทึกการตั้งค่าของท่าน",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "คุกกี้ที่ใช้ในเว็บไซต์",
                            description:
                                'เว็บไซต์นี้ใช้คุกกี้เพื่อให้แน่ใจว่าการทำงานพื้นฐานของเว็บไซต์ และเพื่อปรับปรุงการใช้งานออนไลน์ของท่าน ท่านสามารถเลือกแต่ละหมวดหมู่เพื่อเข้าร่วม / ไม่ใช้ได้ทุกเมื่อที่ท่านต้องการ สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับคุกกี้และข้อมูลความเป็นส่วนตัว โปรดอ่าน <a style="color: blue;" href="PrivacyIndex" target="_blank">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>.',
                        },
                        {
                            title: 'คุกกี้ที่จำเป็น <span class="pm__badge">Always Enabled</span>',
                            description:
                                "คุกกี้พื้นฐานที่จำเป็น เพื่อช่วยให้การทำงานหลักของเว็บไซต์ใช้งานได้ รวมถึงการเข้าถึงพื้นที่ที่ปลอดภัยต่างๆ ของเว็บไซต์ หากไม่มีคุกกี้นี้เว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม และจะใช้งานได้โดยการตั้งค่าเริ่มต้น โดยไม่สามารถปิดการใช้งานได้",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "คุกกี้ประสิทธิภาพและการวิเคราะห์",
                            description:
                                "คุกกี้ประเภทนี้ใช้เพื่อรวบรวมข้อมูลเกี่ยวกับประสิทธิภาพเว็บไซต์ RCBT-SIP รวมถึงการเยี่ยมชมและการใช้งานเว็บไซต์จากผู้ใช้บริการ เช่น จำนวนผู้เข้าชมที่ใช้เว็บไซต์ของ RCBT-SIP และหน้าเว็บยอดนิยมในหมู่ผู้ใช้บริการของ RCBT-SIP",
                            linkedCategory: "analytics",
                            cookieTable: {
                                // caption: 'Cookie table',
                                headers: {
                                    name: "Name",
                                    domain: "Domain",
                                    desc: "Description",
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "rcbt-sip.com/",
                                        desc: "คุกกี้นี้ติดตั้งโดย Google Analytics คุกกี้ใช้เพื่อคำนวณผู้เข้าชม เซสชัน ข้อมูลแคมเปญ และติดตามการใช้งานไซต์สำหรับรายงานการวิเคราะห์ของไซต์ คุกกี้เก็บข้อมูลโดยไม่ระบุชื่อและกำหนดหมายเลขที่สร้างขึ้นแบบสุ่มเพื่อระบุผู้เยี่ยมชมที่ไม่ซ้ำใคร",
                                    },
                                    {
                                        name: "_gid",
                                        domain: "rcbt-sip.com/",
                                        desc: "คุกกี้นี้ติดตั้งโดย Google Analytics คุกกี้ใช้เพื่อเก็บข้อมูลว่าผู้เยี่ยมชมใช้เว็บไซต์อย่างไร และช่วยในการสร้างรายงานการวิเคราะห์ว่าเว็บไซต์เป็นอย่างไร ข้อมูลที่รวบรวมรวมถึงจำนวนผู้เยี่ยมชม แหล่งที่มาและหน้าที่เยี่ยมชมในรูปแบบนิรนาม",
                                    },
                                ],
                            },
                        },
                        {
                            title: 'หากท่านมีปัญหา หรือข้อสงสัยโปรดติดต่อเราที่ <a style="color: blue;" =href="SupportPage" target="_blank">ติดต่อเรา</a>',
                            // description: "Powered by cookie-consent"
                        },
                    ],
                },
            },
        },
        autoDetect: "browser",
    },
});
